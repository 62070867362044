$font-heading: 'Overlock', serif;
$font-body: 'Lato', sans-serif;
$font-mono: 'Courier Prime', monospace;

$color-black: #332501;
$color-primary: #745206;
$color-highlight: #EABF63;
$color-mid-start: #F5EFDE;
$color-mid-end: #E9CD88;
$color-white: #FCFBF8;
$color-black-transparent: rgba(34, 34, 34, .9);

$base-grid-size: .8rem;

$zindex-checkbox: 1;
$zindex-icon: 2;
$zindex-highlight: 10;
$zindex-dropdown: 20;
$zindex-overlay: 30;
$zindex-modal: 100;

$viewport-small: 667px;
$viewport-medium: 1024px;
