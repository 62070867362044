.u-txt--16 {
    font-size: 1.6rem;
}

.u-txt--20 {
    font-size: 2rem;
}

.u-txt--20\@sm {
    @include bpSmall() {
        font-size: 2rem;
    }
}

.u-txt--24 {
    font-size: 2.4rem;
}

.u-txt--24\@sm {
    @include bpSmall() {
        font-size: 2.4rem;
    }
}

.u-txt--36 {
    font-size: 3.6rem;
}

.u-txt--36\@sm {
    @include bpSmall() {
        font-size: 3.6rem;
    }
}

.u-txt--50 {
    font-size: 5rem;
}

.u-txt--lh-1\.4 {
    line-height: 1.4;
}

.u-txt--heading {
    font-family: $font-heading;
}

.u-txt--color-black {
    color: $color-black;
}

.u-txt--color-primary {
    color: $color-primary;
}

.u-txt--color-highlight {
    color: $color-highlight;
}

.u-txt--color-mid-start {
    color: $color-mid-start;
}

.u-txt--color-mid-end {
    color: $color-mid-end;
}

.u-txt--color-white {
    color: $color-white;
}

.u-txt--weight-bold {
    font-weight: 700;
}

.u-txt--weight-black {
    font-weight: 800;
}

.u-txt--letter-spacing-1 {
    letter-spacing: .1rem;
}

.u-txt--align-left {
    text-align: left;
}

.u-txt--align-right {
    text-align: right;
}
