/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 9, 2022 */
@font-face {
    font-family: 'Overlock';
    src: url('../../assets/fonts/overlock-black-webfont.woff2') format('woff2'),
         url('../../assets/fonts/overlock-black-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Overlock';
    src: url('../../assets/fonts/overlock-bold-webfont.woff2') format('woff2'),
         url('../../assets/fonts/overlock-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Overlock';
    src: url('../../assets/fonts/overlock-regular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/overlock-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Courier Prime';
    src: url('../../assets/fonts/courierprime-regular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/courierprime-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}