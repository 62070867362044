.c-basic-land-row {
    display: grid;
    column-gap: $base-grid-size;
    align-items: center;

    grid-template-areas:
        'name field action'
        'pips field action'
        'icon icon icon';

    grid-template-columns: 12rem 5.6rem auto;

    @include bpSmall() {
        grid-template-areas:
            'icon name field action'
            'icon pips field action';

        grid-template-columns: 4.4rem 12rem 5.6rem auto;
    }
}

.c-basic-land-row__icon {
    display: none;

    @include bpSmall() {
        display: block;
        grid-area: icon;
    }
}

.c-basic-land-row__name {
    grid-area: name;
}

.c-basic-land-row__field {
    grid-area: field;
}

.c-basic-land-row__pips {
    grid-area: pips;
}

.c-basic-land-row__action {
    grid-area: action;
}
