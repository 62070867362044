.a-spin {
    animation: spin infinite ease-in-out;
}

.a-spin--1 {
    animation-duration: 4.4s;
}

.a-spin--2 {
    animation-duration: 5s;
    animation-delay: .5s;
}

.a-spin--3 {
    animation-duration: 5.6s;
    animation-delay: 1s;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    
    1% {
        transform: rotate(0deg);
    }
    
    10% {
        transform: rotate(-10deg);
    }
    
    90% {
        transform: rotate(calc(5turn + 10deg));
    }
    
    99% {
        transform: rotate(5turn);
    }
    
    to {
        transform: rotate(5turn);
    }
}