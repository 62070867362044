.o-h-list {
    display: flex;
}

.o-h-list--wrap {
    flex-direction: column;
}

.o-h-list > * + * {
    margin-left: $base-grid-size;
}

.o-h-list--wrap > * + * {
    margin-left: 0;
    margin-top: $base-grid-size;
}

@include bpSmall() {
    .o-h-list--wrap {
        flex-direction: row;
    }

    .o-h-list--wrap > * + * {
        margin-left: $base-grid-size;
        margin-top: 0;
    }
}

.o-h-list--top {
    align-items: flex-start;
}

.o-h-list--baseline {
    align-items: baseline;
}

.o-h-list--center {
    align-items: center;
}

.o-h-list--right {
    justify-content: flex-end;
}

@for $i from 2 through 4 {
    .o-h-list--x#{$i} > * + * {
        margin-left: $base-grid-size * $i;
    }
};
