.c-card-option {
    position: relative;
}

.c-card-option--disabled {
    opacity: .7;
    cursor: default;
}

.c-card-option__button {
    width: 100%;
}

.c-card-option__frame {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $zindex-highlight;
    pointer-events: none;
}

.c-card-option__image {
    display: none;

    @include bpSmall() {
        display: initial;
    }
}

.c-card-option__caption {
    font-family: $font-body;
    display: flex;
    line-height: 4.4rem;
    padding: $base-grid-size;
    font-size: 1.8rem;
    background-color: $color-mid-start;
    width: 100%;

    @include bpSmall() {
        display: none;
    }
}

.c-card-option__frame--selected {
    box-shadow: inset 0 0 0 .6rem $color-highlight;
}

.c-card-option__icon {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: $base-grid-size;
    width: 4.4rem;
    height: 4.4rem;
    background-image: url('../../assets/images/i.svg');
    z-index: $zindex-icon;

    @include bpSmall() {
        bottom: $base-grid-size;
        transform: none;
    }
}
