@use "sass:color";

$color-shadow: color.adjust($color: $color-primary, $lightness: -25%);
$color-secondary-shadow: color.adjust($color: $color-mid-end, $lightness: -25%);
$button-depth: .4rem;

.c-btn {
    padding: $base-grid-size $base-grid-size * 2;
    border-radius: 2.2rem;
    font-family: $font-heading;
    font-size: 2rem;
    font-weight: 800;
    background-color: $color-primary;
    color: $color-white;
    border: none;
    box-shadow: 0 $button-depth 0 0 $color-shadow;
    transform: translateY(0);

    transition: box-shadow .1s, transform .1s;

    @include bpSmall() {
        font-size: 2.4rem;
    }
}

.c-btn:not([disabled]):active {
    box-shadow: 0 0 0 0 $color-shadow;
    transform: translateY($button-depth);
}

.c-btn--secondary {
    background-color: $color-mid-end;
    color: $color-primary;
    box-shadow: 0 $button-depth 0 0 $color-secondary-shadow;
    font-size: 1.6rem;
}

.c-btn--square {
    border-radius: .5rem;
    padding: .8rem;
    line-height: 0;
}

.c-btn[disabled] {
    opacity: .7;
}
