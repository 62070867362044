html {
    padding: 0;
    font-size: 62.5%;
    font-family: $font-body;
    background-color: $color-black;
    min-height: 100%;
}

body {
    font-size: 1.6rem;
    color: $color-black;
    min-height: 100%;
}

button {
    cursor: pointer;
}

* {
    box-sizing: border-box;
}
