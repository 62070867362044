.c-modal {
    position: fixed;
    z-index: $zindex-modal;
}

.c-modal__curtain {
    position: fixed;
    background-color: $color-black-transparent;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.c-modal__body {
    background-color: $color-white;
    position: relative;
    padding: $base-grid-size * 2;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    max-width: 100vw;
    
    @include bpSmall() {
        border: solid .4rem $color-highlight;
        padding: $base-grid-size * 4;
        top: 50%;
        left: 50%;
        right: unset;
        bottom: unset;
        border-radius: 2rem;
        transform: translateX(-50%) translateY(-50%);
        max-height: 90vh;
        width: 500px;
    }
}

.c-modal__body--large {
    @include bpSmall() {
        width: 90vw;
    }
}

.c-modal__icon {
    position: absolute;
    top: $base-grid-size * 2;
    right: $base-grid-size * 2;

    @include bpSmall() {
        top: $base-grid-size * 4;
        right: $base-grid-size * 4;
    }
}
