.c-input {
    height: 4.4rem;
    border-color: $color-primary;
    border-radius: .4rem;
    border-width: .1rem;
    font-family: $font-body;
    font-size: 1.6rem;
    color: $color-black;
    padding: 0 $base-grid-size;
}

.c-input--small {
    width: 4.4rem;
}
